import React from 'react';
import { BackTop, Row, Col } from 'antd';
import SocalMediaIcons from './home-components/home-left/SocialMedia';
import IslamDates from './home-components/home-right/IslamDates';
import HomeAbout from './HomeAbout';
import Navbar from '../../Layout/Navbar/Navbar';
import Footer from '../../Layout/Footer/Footer';
import FacebookFeed from './home-components/home-right/FacebookFeed';
import ImageGallery from './home-components/ImageGallery';
import Contact from '../Contact/Contact';
import './Home.css';

const Home = () => {
  return (
    <div>
      <SocalMediaIcons />
      {/* <a className='contact-popup'>Contact Us</a> */}
      <Navbar />
      <div className='container'>
        <BackTop />
        <ImageGallery />
        <div style={{ margin: '0 auto' }} className='grid-container'>
          <div>
            <HomeAbout />
          </div>
          <div style={{ background: '#f1f1f1', padding: '1rem' }}>
            <FacebookFeed />
            <IslamDates />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
