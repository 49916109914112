import React from 'react';
import Navbar from '../../../Layout/Navbar/Navbar';
import Footer from '../../../Layout/Footer/Footer';

const Youtube = () => {
  return (
    <div>
      <Navbar />
      <div className='container' style={{ paddingTop: '5.5rem' }}>
        <div className='elfsight-app-96e721ac-528b-456d-9d1c-657651d1220f'></div>
      </div>
      <Footer />
    </div>
  );
};

export default Youtube;
