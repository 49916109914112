import React, { Component } from 'react';
import { Form, Input, notification } from 'antd';
import axios from 'axios';
import ContactInfo from './ContactInfo';
const { TextArea } = Input;

export default class ContactBox extends Component {
  constructor() {
    super();
    this.state = { buttonDisabled: false, buttonText: 'Send Message' };
  }

  formRef = React.createRef();

  onFinish = (values) => {
    this.setState({ buttonDisabled: true, buttonText: 'Sending . . .' });
    axios
      .post(`/api/contact`, values)
      .then((res) => {
        notification.success({
          message: res.data.success,
        });
        this.setState({ buttonDisabled: false, buttonText: 'Send Message' });
        this.formRef.current.resetFields();
      })
      .catch((err) => {
        notification.error({
          message: err.response.data.error,
        });
        this.setState({ buttonDisabled: false, buttonText: 'Send Message' });
      });
  };

  onFinishFailed = (errorInfo) => {};

  render() {
    return (
      <div className='contact-form-container drop-shadow'>
        <div className='contact-form-container-width'>
          <h2 style={{ textAlign: 'center' }}>Send us a message</h2>
          <Form
            layout='vertical'
            name='basic'
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
          >
            <div className='input-container'>
              <Form.Item
                label={<h4 htmlFor='name'>Full name</h4>}
                name='name'
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input placeholder='Name' />
              </Form.Item>
            </div>
            <div className='input-container'>
              <Form.Item
                label={<h4 htmlFor='email'>Email</h4>}
                name='email'
                rules={[
                  { required: true, message: 'Please input your email!', type: 'email' },
                ]}
              >
                <Input placeholder='Email' />
              </Form.Item>
            </div>

            <div className='input-container'>
              <Form.Item
                label={<h4 htmlFor='message'>Message</h4>}
                name='message'
                rules={[{ required: true, message: 'Please input your message!' }]}
              >
                <TextArea rows={6} className='contact-message' placeholder='Message' />
              </Form.Item>
            </div>

            <div className='input-container contact-btn-container'>
              <Form.Item>
                <button
                  disabled={this.state.buttonDisabled}
                  className='contact-button main-color-scheme'
                >
                  {this.state.buttonText}
                </button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <ContactInfo />
      </div>
    );
  }
}
