import React, { useState, useEffect } from 'react';
import { Layout, Select, Modal, Button, BackTop } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import ChapterList from './ChapterList.json';
import QuranText from './QuranText';
import Navbar from '../../../Layout/Navbar/Navbar';
const { Header } = Layout;
const { Option } = Select;

const Quran = () => {
  const [values, setValues] = useState({
    currentSurah: ['1', ' ~ ', 'Al-Fatiha', ' ~ ', 'سورة الفاتحة', ' ~ ayat', ' ', '7'],
    surah: { surahNumber: '1', surahAyat: '7' },
    recitersList: '',
    surahNumberAyat: {
      totalNumber: [1, 2, 3, 4, 5, 6, 7],
      ayatList: [1, 2, 3, 4, 5, 6, 7],
      startAyat: 1,
      endAyat: 7,
    },
    visible: false,
    currentReciter: 'shaik_abu_baker_alshatri',
    LanguageTranslation: 'none',
  });

  const {
    LanguageTranslation,
    recitersList,
    surahNumberAyat,
    visible,
    currentReciter,
    currentSurah,
  } = values;

  useEffect(() => {
    fetch(`https://api.mp3quran.net/verse/verse_en.json`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let reciters = [];
        data.reciters_verse.map((reciter) => {
          if (reciter.audio_url_bit_rate_64.length > 3) {
            // Getting value from url
            let orginaRecitersName = reciter.audio_url_bit_rate_64;
            let noUrlReciters = orginaRecitersName.replace(
              'https://verse.mp3quran.net/arabic/',
              ''
            );
            let onlyValueReciter = noUrlReciters.replace('/64/', '');

            reciters.push({
              id: reciter.id,
              name: reciter.name,
              audio: reciter.audio_url_bit_rate_64,
              value: onlyValueReciter,
            });
          }
        });
        setValues({ ...values, recitersList: reciters });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSurahChange(value) {
    let numberArray = [];

    for (let i = 1; i <= Number(value.label[7]); i++) {
      numberArray.push(i);
    }

    setValues({
      ...values,
      currentSurah: value.label,
      surah: {
        surahNumber: value.label[0],
        surahAyat: value.label[6],
      },
      surahNumberAyat: {
        totalNumber: numberArray,
        startAyat: numberArray[0],
        endAyat: numberArray[numberArray.length - 1],
        ayatList: numberArray,
      },
    });
  }

  const handleReciterChange = (value) => {
    setValues({ ...values, currentReciter: value.value });
  };
  const handleStartChange = (value) => {
    // Checks end value is less then start
    if (value > surahNumberAyat.endAyat) {
      return alert('Ayat End Must Be larger than start!');
    }

    // Ayat lenght
    let numberArray = [];

    for (let i = Number(value); i <= Number(surahNumberAyat.endAyat); i++) {
      numberArray.push(i);
    }

    setValues({
      ...values,
      surahNumberAyat: {
        totalNumber: surahNumberAyat.totalNumber,
        ayatList: numberArray,
        startAyat: value,
        endAyat: surahNumberAyat.endAyat,
      },
    });
  };
  const handleEndChange = (value) => {
    // Checks end value is less then start
    if (value < surahNumberAyat.startAyat) {
      return alert('Ayat End Must Be larger than start!');
    }

    // Ayat lenght
    let numberArray = [];

    for (let i = Number(surahNumberAyat.startAyat); i <= Number(value); i++) {
      numberArray.push(i);
    }

    setValues({
      ...values,
      surahNumberAyat: {
        totalNumber: surahNumberAyat.totalNumber,
        ayatList: numberArray,
        startAyat: surahNumberAyat.startAyat,
        endAyat: value,
      },
    });
  };

  const handleLangChange = (value) => {
    setValues({
      ...values,
      LanguageTranslation: value,
    });
  };

  // modal functions
  const showModal = () => {
    setValues({ ...values, visible: true });
  };

  const handleOk = () => {
    setValues({ ...values, visible: false });
  };

  const handleCancel = () => {
    setValues({ ...values, visible: false });
  };

  return (
    <div>
      <Navbar />
      <BackTop />
      <div className='container' style={{ paddingTop: '5rem' }}>
        <Layout>
          <Header
          // style={{
          //   width: '100%',
          //   display: 'flex',
          //   justifyContent: 'space-between',
          //   position: 'fixed',
          //   zIndex: 1,
          //   top: '5rem',
          //   left: 0,
          //   width: '100%',
          // }}
          >
            <div>
              <Button icon={<SettingOutlined />} type='primary' onClick={showModal}>
                Setting / Options
              </Button>
              <Modal
                title='Title'
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                {/* Chapter */}
                <div>
                  <label style={{ marginRight: '0.5rem' }}>Surah</label>
                  <Select
                    labelInValue
                    defaultValue={{ key: 'Al-Fatiha' }}
                    style={{ width: 350, margin: '1rem auto' }}
                    onChange={handleSurahChange}
                  >
                    {ChapterList.map((chapter) => (
                      <Option value={chapter.latin} key={chapter.id}>
                        {chapter.id} ~ {chapter.latin} ~ {chapter.arabic} ~ ayat{' '}
                        {chapter.ayat}
                      </Option>
                    ))}
                  </Select>
                </div>

                {/* Reciters */}
                <div>
                  <label style={{ marginRight: '0.1rem' }}>Reciter</label>
                  <Select
                    labelInValue
                    style={{ width: 350, margin: '1rem auto' }}
                    defaultValue={{ key: 'shaik_abu_baker_alshatri' }}
                    onChange={handleReciterChange}
                  >
                    {recitersList &&
                      recitersList.map((reciter) => (
                        <Option value={reciter.value} key={reciter.id}>
                          {reciter.name}
                        </Option>
                      ))}
                  </Select>
                </div>

                {/* From */}
                <div>
                  <label style={{ marginRight: '0.5rem' }}>Start</label>
                  <Select
                    style={{ width: 350, margin: '1rem auto' }}
                    optionFilterProp='children'
                    onChange={handleStartChange}
                    value={`${surahNumberAyat.startAyat}`}
                  >
                    {surahNumberAyat.totalNumber &&
                      surahNumberAyat.totalNumber.map((ayat, index) => (
                        <Option value={ayat} key={index}>
                          {ayat}
                        </Option>
                      ))}
                  </Select>
                </div>

                {/* To */}
                <div>
                  <label style={{ marginRight: '0.5rem' }}>Finish</label>
                  <Select
                    style={{ width: 350, margin: '1rem auto' }}
                    optionFilterProp='children'
                    onChange={handleEndChange}
                    value={`${surahNumberAyat.endAyat}`}
                  >
                    {surahNumberAyat.totalNumber &&
                      surahNumberAyat.totalNumber.map((ayat, index) => (
                        <Option value={ayat} key={index}>
                          {ayat}
                        </Option>
                      ))}
                  </Select>
                </div>

                {/* Language */}
                <div>
                  <label style={{ marginRight: '0.5rem' }}>Language</label>
                  <Select
                    style={{ width: 350, margin: '1rem auto' }}
                    optionFilterProp='children'
                    onChange={handleLangChange}
                    value={LanguageTranslation}
                  >
                    <Option data-direction='ltr' value='none'>
                      None
                    </Option>
                    <Option data-direction='ltr' value='en'>
                      English
                    </Option>
                    <Option data-direction='ltr' value='fr'>
                      Français
                    </Option>
                    <Option data-direction='ltr' value='tr'>
                      Türkçe
                    </Option>

                    <Option data-direction='rtl' value='ur'>
                      Urdu
                    </Option>
                    <Option data-direction='ltr' value='bs'>
                      Bosanski
                    </Option>

                    <Option data-direction='ltr' value='id'>
                      Indonesia
                    </Option>
                    <Option data-direction='ltr' value='pt'>
                      Português
                    </Option>
                    <Option data-direction='ltr' value='ha'>
                      Hausa
                    </Option>
                  </Select>
                </div>
              </Modal>
            </div>
            {/* <div>
              <h1 style={{ color: '#f4f4f4' }}>{currentSurah.join(' ')}</h1>
            </div> */}
          </Header>
          <QuranText SurahInfo={values} />
        </Layout>
      </div>
    </div>
  );
};

export default Quran;
