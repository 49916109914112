import React, { useEffect } from "react";
import Navbar from "../../Layout/Navbar/Navbar";
import Footer from "../../Layout/Footer/Footer";
import "./About.css";

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div className='container about-container'>
        <div className='about-banner'>
          <div className='about-text'>About Us</div>
        </div>

        <div className='about-text-container'>
          <div className='announcements-title'>About Sakeenat Ibad-ur-Rehman</div>
          <div className='announcements-text'>
            Sakeenat Ibad-ur-Rehman has been serving thirteen years its local community
            through in-person classes, and is outreaching muslims in United States and
            globaly through its conference phone sessions.
          </div>
        </div>

        <div className='about-text-container'>
          <div className='announcements-title'>Teacher Profile</div>
          <div className='announcements-text'>
            Aalima Nikhat Haider the founder of 'Sakeenat Ibrad-ur-Rehman' is a keen
            teacher who generously shares her in-depth knowledge of the Noble Quran. She
            went throught a rigorus journey of 18 years in her quest to learn the Noble
            Quran. She did her Aalima course from 'Al Ehsan.net'. Received her
            certification from Shaikh Abdul Basit Faheem Mudarris at Masjid-e-Nabvi. She
            has received Iijaza of Shahi Bukhari and is 26th in the chain of
            narrators/Raveez. Expertise: Quran, Hadith, Fiqh, Arabic Grammer, Virasat,
            Islamic History.
          </div>
        </div>

        <div className='about-text-container'>
          <div className='announcements-title'>Goal</div>
          <div className='announcements-text'>
            Sakeenat Ibad-ur-Rehman's goal is not only to graduate students with
            certification but is to bring a positive change and 'sakeena' (peace) in their
            lives. Thousands of muslims are currently benefiting from its programs.
          </div>
        </div>

        <div className='about-text-container'>
          <div className='announcements-title'>Sakeenat Ibad-ur-Rehman Aim</div>
          <div className='announcements-text'>
            To spread the word of Allah S.W.T to every person regardless of
            age,education,location or cultural background
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
