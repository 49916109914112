import React, { useEffect } from "react";
import Card from "./Card";
import Navbar from "../../../Layout/Navbar/Navbar";
import "../../Admin/Home.css";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div className='container' style={{ marginTop: "5rem" }}>
        <div className='admin-home-container'>
          <div style={{ textAlign: "center" }}>
            <div className='admin-title'>Sakeenat Institute Lectures</div>
          </div>

          <div className='admin-body-container'>
            <Card />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
