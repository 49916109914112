import React, { useState } from 'react';
import SeeratUnNabiLectures from './Seerat-Un-Nabi-Lectures'
import MondayDarsLectures from './MondayDarsLectures'

const Card = () => {

  const [seeratUnNabi , setSeeratunNabi ] = useState("none");
  const [namaazenabwi , setnamaazenabwi ] = useState("none");
  const [MondayDars , setMondayDars ] = useState("none");


  const seeratUnNabiFunc = () => {
    setSeeratunNabi("block")
    setnamaazenabwi("none")
    setMondayDars("none")
  }

  const namaazenabwiFunc = () => {
    setSeeratunNabi("none")
    setnamaazenabwi("block")
    setMondayDars("none")
  }

  const MondayDarsFunc = () => {
    setSeeratunNabi("none")
    setnamaazenabwi("none")
    setMondayDars("block")
  }

  return (
    <div className='admin-card-container'>

      {/* BUTTONS FOR CLASSES */}
      <button onClick={seeratUnNabiFunc} className="main-color-scheme" style={{padding: "0.5rem", width: "100%", margin: '0.5rem 0 ',marginTop: '2rem', fontSize: '1.3rem',letterSpacing: "1px"}}>Seerat un Nabi Lectures</button>
      <button className="main-color-scheme" onClick={namaazenabwiFunc} style={{padding: "0.5rem", width: "100%", margin: '0.5rem 0 ', fontSize: '1.3rem',letterSpacing: "1px"}}>namaaz e nabwi Lectures</button>
      <button className="main-color-scheme" onClick={MondayDarsFunc} style={{padding: "0.5rem", width: "100%", margin: '0.5rem 0 ', fontSize: '1.3rem',letterSpacing: "1px"}}>Monday Dars Lectures</button>

      

      <div style={{display: seeratUnNabi}}> 
        <SeeratUnNabiLectures />
        </div>

        <div style={{display: namaazenabwi}}> 
        <h1>Currently No lectures Here</h1>        </div>


        <div style={{display: MondayDars}}> 
<MondayDarsLectures />
      </div>





      {/* http://docs.google.com/uc?export=open&id= */}
    </div>
  );
};

export default Card;
