import React from 'react';

const IslamDates = () => {
  return (
    <div className='islam-widget-container'>
      <iframe
        className='islamEvents'
        scrolling='no'
        src='https://www.islamicfinder.org/specialislamicdays'
      ></iframe>
      <iframe
        id='iframe'
        title='prayerWidget'
        className='widget-m-top'
        className='prayerTimes'
        scrolling='no'
        src='https://www.islamicfinder.org/prayer-widget/4887398/shafi/5/0/15.0/15.0'
      ></iframe>
    </div>
  );
};

export default IslamDates;
