import React from 'react';
import {
  FacebookOutlined,
  YoutubeOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

const ContactInfo = () => {
  return (
    <div className='contact-information-container'>
      <h2 className='contact-title'>Contact Information</h2>

      <div className='contact-dec-container'>
        <div className='contact-icon'>
          <EnvironmentOutlined />
        </div>
        <div className='contact-dex-text'>
          300 W Highridge Rd. Villa Park, Illinois 60181
        </div>
      </div>

      <div className='contact-dec-container'>
        <div className='contact-icon'>
          <ClockCircleOutlined />
        </div>
        <div className='contact-dex-text'>11:00 AM - 5:00 PM</div>
      </div>

      <div className='contact-dec-container'>
        <div className='contact-icon'>
          <PhoneOutlined />
        </div>
        <a href='tel:630-397-1944' className='contact-dex-text contact-link'>
          (630) 397-1944
        </a>
      </div>

      <div className='contact-dec-container'>
        <div className='contact-icon'>
          <MailOutlined />
        </div>
        <a href='mailto:nikhathaider@yahoo.com' className='contact-dex-text contact-link'>
          nikhathaider@yahoo.com
        </a>
      </div>

      <div className='contact-dec-container'>
        <div className='contact-icon youtube-icon'>
          <YoutubeOutlined />
        </div>
        <a
          target='_blank'
          href='https://www.youtube.com/channel/UCpsomIgMLpMoc4C0ePOOFdg/'
          className='contact-dex-text contact-link'
        >
          Sakeenat.org
        </a>
      </div>

      <div className='contact-dec-container'>
        <div className='contact-icon facebook-icon'>
          <FacebookOutlined />
        </div>
        <a
          href='https://www.facebook.com/Sakeenat-Ibadurahman-Institute-102942941057060/'
          target='_blank'
          className='contact-dex-text contact-link'
        >
          Sakeenat Ibad-ur-Rehman
        </a>
      </div>
    </div>
  );
};

export default ContactInfo;
