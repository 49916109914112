import React, { useEffect, useState } from "react";
import UpcomingEvents from "./home-components/home-left/UpcomingEvents";
import Announcements from "./home-components/home-left/Announcements";
import AboutHome from "./home-components/home-left/AboutHome";
import CurrentClasses from "./home-components/home-left/CurrentClasses";
import Flyer from "./home-components/home-left/Flyer";
import Fade from "react-reveal/Fade";
import scrollIntoView from "scroll-into-view-if-needed";
import { withRouter } from "react-router-dom";

const HomeAbout = ({ location }) => {
  const [scrollValue, setScrollValue] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (location.state) {
      setScrollValue(location.state.scrollTo);
    }
  }, [location]);

  return (
    <div className='home-about-container scrolling-box'>
      {scrollValue
        ? scrollValue === "none"
          ? window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          : scrollIntoView(document.getElementById(scrollValue), {
              behavior: "smooth",
              scrollMode: "always",
              block: "center",
            })
        : " "}
      <Fade left>
        <div className='home-about-sections' id='about'>
          <div className='home-about-passage-title'>
            Sakeenat Ibadurahman Institue
          </div>
          <AboutHome />
        </div>
      </Fade>

      <Fade left>
        <div className='home-about-sections' id='about'>
          <div className='home-about-passage-title'>Click To View Flyer</div>
          <Flyer />
        </div>
      </Fade>

      <Fade left>
        <div className='home-about-sections' id='announcements'>
          <div className='home-about-passage-title'>Announcements</div>
          <Announcements />
        </div>
      </Fade>

      <Fade left>
        <div className='home-about-sections' id='classes'>
          <div className='home-about-passage-title'>Our Classes </div>
          <CurrentClasses />
        </div>
      </Fade>

      {/* <Fade left>
        <div className='home-about-sections' id='events'>
          <div className='home-about-passage-title'>Upcoming Events</div>
          <UpcomingEvents />
        </div>
      </Fade> */}
    </div>
  );
};

export default withRouter(HomeAbout);
