import React, { useState, useEffect } from 'react';
import { Spin, BackTop } from 'antd';
import axios from 'axios';
import Navbar from '../../Layout/Navbar/Navbar';
import Footer from '../../Layout/Footer/Footer';
import './IslamKnowledge.css';

const IslamNames = () => {
  const [values, setValues] = useState({
    dataSource: '',
  });

  const { dataSource } = values;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    axios
      .get('https://api.aladhan.com/asmaAlHusna')
      .then((data) => {
        let dataSource = [];

        data.data.data.map((value, index) => {
          dataSource.push({
            key: index,
            name: value.name,
            translation: value.transliteration,
            number: value.number,
            meaning: value.en.meaning,
          });
        });

        setValues({ ...values, dataSource });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Navbar />
      <BackTop />
      <div className='container'>
        <div style={{ paddingTop: '5rem' }}>
          <div className='about-banner'>
            <div className='about-text'>Allah Names</div>
          </div>
        </div>
        {dataSource ? (
          <table className='table' style={{ margin: '0 auto' }}>
            <thead className='thead'>
              <tr className='tr'>
                <th className='th'>Name</th>
                <th className='th'>Translation</th>
                <th className='th'>Meaning</th>
                <th className='th'>Number</th>
              </tr>
            </thead>
            <tbody>
              {dataSource.map((value) => (
                <tr key={value.number} className='tr'>
                  <td className='td' data-column='Name'>
                    <span style={{ fontSize: '2rem' }}>{value.name}</span>
                  </td>
                  <td className='td' data-column='Translation'>
                    {value.translation}
                  </td>
                  <td className='td' data-column='Meaning'>
                    {value.meaning}
                  </td>
                  <td className='td' data-column='Number'>
                    {value.number}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div style={{ textAlign: 'center', margin: '10rem 0' }}>
            <Spin size='large' />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default IslamNames;
