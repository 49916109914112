import React from 'react';
import {
  FacebookFilled,
  TwitterOutlined,
  GooglePlusOutlined,
  YoutubeFilled,
} from '@ant-design/icons';

const SocialMedia = () => {
  return (
    <div className='icon-bar'>
      <a
        href='https://www.facebook.com/Sakeenat-Ibadurahman-Institute-102942941057060/'
        target='_blank'
        className='facebook'
      >
        <FacebookFilled />
      </a>
      {/* <a href='#' className='twitter'>
        <TwitterOutlined />
      </a>
      <a href='#' className='google'>
        <GooglePlusOutlined />
      </a> */}
      <a
        href='https://www.youtube.com/channel/UCpsomIgMLpMoc4C0ePOOFdg/'
        target='_blank'
        className='youtube'
      >
        <YoutubeFilled />
      </a>
    </div>
  );
};

export default SocialMedia;
