import React from 'react'

const SeeratUnNabiLectures = () => {
	return (
		<div>
			                 {/* Class */}
											 <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 1)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1JN2bwWyxws5_G_Nh61AOCCve501_4wLP'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1R5XF9ubK4D5jbCztR9XzInvX0FiJEnfg'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=15MBetcwtjQGOJCgWP9Fd0FVyYgIwv5Pd'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>

                       {/* Class */}
                       <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 2)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1kmDNDY7QPUiJ_JTUPvzz8r7A1rTZnVGE'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1jr6tnpwWU1K_dRap1Zpdn-IaKS-RNI44'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1-6Xz75s-PdGQduDFuObvOfa9gNUomblk'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>

                       {/* Class */}
                       <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 3)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=15-b4UBSaiTCUZrQNNwxfJjUhJ6JWzRq2'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1LMJVM9jKr53SmE5OgjFC3q19xX9lrKGi'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1750yD6lHNvRtP085H1GKOXkPDXgPvIQC'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>

             {/* Class */}
             <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 4)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1YaWtA2QaxSU9317x74uwLl21u21q4q3c'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1KVPUDzBivmyAEBiExHia-MSjPvf0WPP2'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1stvhWyE4vNdlrmd490DHaZc8QVnS-f8E'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>

          {/* Class */}
          <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 5)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1I_FztX59SjOWcjGULhnZVKYn15qUrjnJ'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1nCs9jPM73xJ_vw_EoVWXptkm4X2KfT9j'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1_Cp-Apipaxz4UXcwG4QfdhigC-XzAeyn'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>


          {/* Class */}
          <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 6)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1T9vEWg9ivFYCQQkOafXeSjJ7kn1IWEg_'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1vAvGoqvKeB0H20zsk9mV3y6IMyZIZNL6'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1gCYFbXbJEFczYLcpf92agJ2LNCi_L61U'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>

      

          {/* Class */}
          <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 7)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1NzyQLcUmbe2mclippu1fYck7o0P-dfDm'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=16thjvptIHYCJvQdlgw6s7A98A1QJmubB'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1c_jAB0mRDzUP_oPzxOfKUAlgehUtuMc8'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>

       {/* Class */}
       <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 8)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1rXZqmTPxvzqh2_7BhHyxWYgme72wHZHK'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1L5CffduE6dwVkax8TBP37yQhojvKcvp-'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1sdNjNmmHRGZSP-UgliybMUuQ1mV0xdNU'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>

            {/* Class */}
            <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 9)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=13p91RXrgQrfsXSVa6fbN9u0jLMru_oDT'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1UTkzagFmSMkSjI4ALjJm5cGnpik4Vy6Q'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1F5SBJS9YowOdr9HpJX_oua1rDyjUsx5y'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>


            {/* Class */}
            <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Seerat un Nabi s.a.w. (Series Part 10)</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1ZRkEo2YhuMlJRlhmJwyHc2xdMDJH4k1i'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1hLXoAUoRIPIN6Izm6_UgpNV7RisPKsNI'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1ezVFHmT2PIw95lwpL1wFCWw2lna4uSD2'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>
		</div>
	)
}

export default SeeratUnNabiLectures;
