import React, { Component } from "react";
import { Menu, Dropdown } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import DrawerNavbar from "./DrawerNavbar";
import SakeenatLogo from "../../../assets/imageedit_7_6831998141.png";
import classnames from "classnames";
import "./Navbar.css";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  navigation = (path) => {
    if (path === this.props.match.path) {
      return {
        style: { color: "#03a87c" },
        class: "nav-link-top middle-black middle-visable-black",
      };
    } else {
      return {
        style: { color: "black" },
        class: "nav-link-top middle-black ",
      };
    }
  };

  render() {
    return (
      <div>
        <DrawerNavbar />
        <div
          className='navbar'
          className={classnames("navbar", {
            "navbar--hidden": !this.state.visible,
          })}
        >
          <div className='nav-container'>
            <div className='nav-icon'>
              <Link
                to={{
                  pathname: "/",
                  state: {
                    scrollTo: "none",
                  },
                }}
              >
                <img className='nav-logo' src={SakeenatLogo} alt='Logo' />
              </Link>
            </div>
            <div>
              <Dropdown
                trigger={["click"]}
                className='hoverable'
                overlay={
                  <Menu>
                    <Menu.Item>
                      <Link to='/'>Home</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to={{
                          pathname: "/",
                          state: {
                            scrollTo: "about",
                          },
                        }}
                      >
                        About
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to={{
                          pathname: "/",
                          state: {
                            scrollTo: "classes",
                          },
                        }}
                      >
                        Classes
                      </Link>
                    </Menu.Item>
                    {/* <Menu.Item>
                      <Link
                        to={{
                          pathname: '/',
                          state: {
                            scrollTo: 'events',
                          },
                        }}
                      >
                        Events
                      </Link>
                    </Menu.Item> */}
                    <Menu.Item>
                      <Link
                        to={{
                          pathname: "/",
                          state: {
                            scrollTo: "announcements",
                          },
                        }}
                      >
                        Announcements
                      </Link>
                    </Menu.Item>
                  </Menu>
                }
              >
                <span
                  style={this.navigation("/").style}
                  className={this.navigation("/").class}
                >
                  Home
                </span>
              </Dropdown>
              <Link
                style={this.navigation("/about").style}
                to='/about'
                className={this.navigation("/about").class}
              >
                About Us
              </Link>

              <Dropdown
                trigger={["click"]}
                className='hoverable'
                overlay={
                  <Menu>
                    <Menu.Item>
                      <Link to='/lectures/youtube'>Youtube Lectures</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to='/lectures'>Audio Lectures</Link>
                    </Menu.Item>
                  </Menu>
                }
              >
                <span
                  style={this.navigation("/lectures").style}
                  className={this.navigation("/lectures").class}
                >
                  Lectures
                </span>
              </Dropdown>

              <Dropdown
                trigger={["click"]}
                className='hoverable'
                overlay={
                  <Menu>
                    <Menu.Item>
                      <Link to='/islamic-knowledge/allah-names'>Allah Names</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        rel='noopener noreferrer'
                        target='_blank'
                        href='https://quran.com/'
                      >
                        Full Quran
                      </a>
                    </Menu.Item>
                  </Menu>
                }
              >
                <span
                  style={this.navigation("/islamic-knowledge").style}
                  className={this.navigation("/islamic-knowledge").class}
                >
                  Islamic Knowledge
                </span>
              </Dropdown>

              <Link
                style={this.navigation("/contacts").style}
                to='/contacts'
                className={this.navigation("/contacts").class}
              >
                Contact
              </Link>

              <Link to='/sign-in'>
                <button className='sign-in-button main-color-scheme'>
                  <LoginOutlined /> Sign In
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Navbar);
