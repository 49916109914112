import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { authenticate, isAuth } from '../../helpers/auth';
import AuthBanner from './AuthBanner';
import './auth.css';

const SignIn = ({ history }) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const onFinish = (values) => {
    setButtonLoading(true);
    axios
      .post(`/api/signin`, values)
      .then((response) => {
        setButtonLoading(false);
        authenticate(response, () => {

          if (isAuth() && isAuth().role === 'user') { history.push('/admin/home/tafseer-quran'); };
          if (isAuth() && isAuth().role === 'bukhari') { history.push('/admin/home/sahi-bukhari'); };
          if (isAuth() && isAuth().role === 'admin') { history.push('/admin'); };
        });
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.error,
        });
        setButtonLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => { };

  const signInForm = (
    <Form
      name='basic'
      layout='vertical'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label='Email'
        name='email'
        rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
      >
        <Input placeholder='Email' />
      </Form.Item>

      <Form.Item
        label='Password'
        name='password'
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password placeholder='Password' />
      </Form.Item>
      <div className='sign-in-bottom'>
        <Form.Item>
          <button disabled={buttonLoading} className='contact-button submit-sign-in'>
            {buttonLoading ? 'Submiting . . .' : 'Submit'}
          </button>
        </Form.Item>

        {/* <Link to='/forgot-password' className='auth-ref'>
          Forgot Password
        </Link> */}
      </div>
    </Form>
  );

  return (
    <div >
      <AuthBanner />
      <div className='auth-input-container' >
        <h1 className='input-title'>
          <div className='sign-in-title'>Sign In</div>
          <Link to='/'>
            <div className='back-button'>
              <ArrowLeftOutlined /> Back
            </div>
          </Link>
        </h1>
        {signInForm}
      </div>
    </div>
  );
};

export default SignIn;

// main-color-scheme-inverted <- old back button
// main-color-scheme <- old submit button