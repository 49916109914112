import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { MediaPlayerControls } from '@cassette/player';
import { PlayerContextProvider } from '@cassette/core';
import '@cassette/player/dist/css/cassette-player.css';
const { Header, Content, Footer } = Layout;

const QuranText = ({ SurahInfo }) => {
  const [ayats, setAyats] = useState('');
  const [playlist, setPlaylist] = useState('');
  const [currentAudio, setCurrentAudio] = useState('');

  const formatNum = (value) => {
    if (value.toString().length === 1) {
      return `00${value}`;
    }

    if (value.toString().length === 2) {
      return `0${value}`;
    }

    if (value.toString().length === 3) {
      return `${value}`;
    }
  };

  useEffect(() => {
    const playlist = [
      {
        // Starting Surah
        url: `https://verse.mp3quran.net/arabic/${
          SurahInfo.currentReciter
        }/64/${formatNum(SurahInfo.surah.surahNumber)}000.mp3`,
        title: `${SurahInfo.currentSurah[0]}. ${SurahInfo.currentSurah[2]} ~ ${SurahInfo.currentSurah[4]} ~ ayat 0`,
        ayatId: '',
      },
    ];

    SurahInfo.surahNumberAyat.ayatList.map((value, index) => {
      playlist.push({
        url: `https://verse.mp3quran.net/arabic/${
          SurahInfo.currentReciter
        }/64/${formatNum(SurahInfo.surah.surahNumber)}${formatNum(value)}.mp3`,
        title: `${SurahInfo.currentSurah[0]}. ${SurahInfo.currentSurah[2]} ~ ${
          SurahInfo.currentSurah[4]
        } ~ ayat ${Number(value)}`,
        ayatId: Number(value),
      });
    });

    setPlaylist(playlist);

    fetch(
      `https://api.mp3quran.net/api/ayat?surah=${SurahInfo.surah.surahNumber}&from=${
        SurahInfo.surahNumberAyat.startAyat
      }&to=${SurahInfo.surahNumberAyat.endAyat}&language=${
        SurahInfo.LanguageTranslation.toLowerCase() === 'none'
          ? 'ar'
          : SurahInfo.LanguageTranslation
      }`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const surahAyats = Object.keys(data).map((i) => data[i]);
        setAyats(surahAyats);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [SurahInfo]);

  const currentAyat = (currentAudio) => {
    setCurrentAudio(Number(currentAudio.ayatId));
  };

  return (
    <div>
      <Content className='site-layout' style={{ padding: '0 50px', marginTop: 64 }}>
        <div style={{ minHeight: 380 }}>
          <h1
            className={
              currentAudio === 0 ? 'current-ayat-title quran-title' : 'quran-title'
            }
            style={{ textAlign: 'center', fontSize: '2.5rem' }}
          >
            أعوذ بالله من الشيطان الرجيم
          </h1>
          <div style={{ display: 'flex' }}>
            <div
              style={{ width: '40rem' }}
              className={
                SurahInfo.LanguageTranslation.toLowerCase() === 'none' ? 'noDisplay' : ''
              }
            >
              {ayats &&
                ayats.map((cur, index) => (
                  <div
                    style={{
                      margin: '0 auto',
                      width: '100%',
                      display: 'inline',
                    }}
                    key={index}
                  >
                    {document.querySelector('.current-ayat') &&
                    currentAudio === cur.aya_id
                      ? document.querySelector('.current-ayat').scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      : ''}
                    <h2
                      style={{ width: '100%', marginBottom: '2rem', padding: '0.4rem' }}
                      className={currentAudio === cur.aya_id ? 'current-ayat' : ''}
                    >
                      {cur.ayah_text_lang}
                    </h2>
                  </div>
                ))}
            </div>
            <div
              className={
                SurahInfo.LanguageTranslation.toLowerCase() === 'none' ? 'noDisplay' : ''
              }
              style={{
                minHeight: '100%',
                padding: '0.2rem',
                background: '#000',
                margin: '0 1rem',
              }}
            ></div>
            <div>
              {ayats &&
                ayats.map((cur, index) => (
                  <div
                    style={{
                      margin: '0 auto',
                      width: '100%',
                    }}
                    key={index}
                  >
                    {document.querySelector('.current-ayat') &&
                    currentAudio === cur.aya_id
                      ? document.querySelector('.current-ayat').scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      : ''}
                    <img
                      className={currentAudio === cur.aya_id ? 'current-ayat' : ''}
                      src={cur.image_url.replace('http', 'https')}
                      alt='ayat'
                      style={{
                        width: `100%`,
                        marginBottom: '0.2rem',
                        padding: '0.4rem',
                        float: 'right',
                        display: 'block',
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Content>
      <Footer
        style={{
          position: 'fixed',
          zIndex: 1,
          bottom: 0,
          left: 0,
          width: '100%',
          background: '#333',
          height: '6rem',
        }}
      >
        {playlist && (
          <PlayerContextProvider
            getMediaTitleAttributeForTrack={currentAyat}
            playlist={playlist}
          >
            <MediaPlayerControls
              controls={[
                'spacer',
                'backskip',
                'playpause',
                'forwardskip',
                'spacer',
                'progress',
                'volume',
                'repeat',
              ]}
            />
          </PlayerContextProvider>
        )}
      </Footer>
    </div>
  );
};

export default QuranText;
