import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import Home from "./components/Pages/Home/Home";
import About from "./components/Pages/About/About";
import Contact from "./components/Pages/Contact/Contact";
import YoutubeLectures from "./components/Pages/Audio-Lectures/YoutubeLectures/Youtube";
import NikhatAapaLectures from "./components/Pages/Audio-Lectures/Nikhat-Aapa-Lectures/Home";
import IslamNames from "./components/Pages/Islam-Knowledge/IslamNames";
import Quran from "./components/Pages/Islam-Knowledge/Quran-App/Quran";

// Auth related routes
import Signin from "./components/auth/SignIn";
// import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from "./components/auth/ResetPassword";

// Private Routes
import TafseerQuranRoute from "./TafseerQuranRoute";
import TafseerQuranClass from "./components/Pages/Admin/Tafseer-Quran-Class/Home";

// 404
import NotFound from "./components/Pages/NotFound";

// Sakeenat Class
import SahiBukhariRoute from "./SahiBukhariRoute";
import SahiBukhariClass from "./components/Pages/Admin/Sahi-Bukhari-Class/Home";


// Admin
import AdminRoute from "./Admin";
import AdminLecture from "./components/Pages/Admin/Admin-Lectures/Admin";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/about' component={About} />
        <Route exact path='/lectures/youtube' component={YoutubeLectures} />
        <Route exact path='/lectures' component={NikhatAapaLectures} />
        <Route exact path='/contacts' component={Contact} />
        <Route exact path='/islamic-knowledge/allah-names' component={IslamNames} />
        <Route exact path='/islamic-knowledge/quran' component={Quran} />
        <Route exact path='/sign-in' component={Signin} />
        {/* <Route exact path='/forgot-password' component={ForgotPassword} /> */}
        <Route exact path='/auth/password/reset/:token' component={ResetPassword} />
        <TafseerQuranRoute
          exact
          path='/admin/home/tafseer-quran'
          component={TafseerQuranClass}
        />
        <SahiBukhariRoute
          exact
          path='/admin/home/sahi-bukhari'
          component={SahiBukhariClass}
        />
        <AdminRoute
          exact
          path='/admin'
          component={AdminLecture}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
