import React, { useState, useEffect } from "react";
import axios from 'axios';
import AWS from 'aws-sdk';
import { notification } from 'antd';

const Card = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedOption, setSelectedOption] = useState('option2');
    const [dropDown, setDropDown] = useState(false);
    const [audioUrl, setAudioUrl] = useState(''); // State to store the audio URL
    const [audioUrlload, setaudioUrlload] = useState(false); // State to store the audio URL
    const [currentLectureName, setCurrentLectureName] = useState(''); // State to store the current lecture name
    const [search, setSearch] = useState(""); // State for search input

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('/api/getLecture', { type: "Sahi Bukhari Class" });
                setData(response.data.reverse());
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const filteredData = data ? data.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
    ) : [];

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    const handleOptionChange = (event) => {
        setData(data.reverse());
        setSelectedOption(event.target.value);
        console.log(event.target.value);
    };

    const DropDownFunc = () => {
        setDropDown(!dropDown);
    };

    const handleLinkClick = (url, lectureName) => {
        setaudioUrlload(false);

        //const idMatch = url.match(/id=([a-zA-Z0-9_-]+)/);

        const regex = /id=([^&]+)/;
        const idMatch = url.match(regex);


        console.log(idMatch);
        if (idMatch) {
            const id = idMatch[1];


            // Configure the AWS SDK to use Wasabi
            const s3 = new AWS.S3({
                endpoint: 's3.wasabisys.com', // Replace with the appropriate endpoint
                accessKeyId: 'I8ITVDL3GT2TQA0ZFSAB', // Replace with your access key
                secretAccessKey: 'DszVpDdzqP1si8PUybJQ3ae3784AJ1vTSN2sqRTq', // Replace with your secret key
                region: 'us-east-1'// Replace with the appropriate region
            });



            const params = {
                Bucket: 'sakeenat', // Replace with your bucket name
                Key: `${id}.mp4`, // Replace with the key of the file you want to retrieve
                Expires: 60 // URL expiry time in seconds
            };



            s3.getSignedUrl('getObject', params, (err, url) => {

                if (err) {
                    notification.error({
                        message: "Unable to open this lecture",
                    });
                    console.log('error', err);
                } else {
                    // window.open(url, '_blank');
                    setAudioUrl(url);
                    setCurrentLectureName(lectureName); // Set the current lecture name
                    setaudioUrlload(true);
                }
            });
        }
        // Open the URL in a new tab
    };
    function removeSpecificPhrase(inputString, phraseToRemove) {
        // Use the replace method with a regex that matches the exact phrase
        const resultString = inputString.replace(new RegExp(phraseToRemove, 'g'), '');

        // Return the modified string
        return resultString;
    }

    return (
        <div>
            <select className="slectedoption slectedoptions" value={selectedOption} onChange={handleOptionChange}>
                <option value="option1">Oldest to Newest Lectures</option>
                <option value="option2">Newest to Oldest lectures</option>
            </select>

            <button className="dropDowmLectures" onClick={DropDownFunc} >Lectures #1 - #23</button>
            <div style={{ marginBottom: '8rem' }}>
                <div style={{ display: dropDown ? "block" : "none" }}>


                    <ul className="recording-list">
                        <li>SAHIBUKHARI 0001 - Class 1 by Alima Nikhat Haider Aapa | Date: Tuesday, September 3, 2019 | Topic: "LIFE OF IMAM ABU BUKHARI R.A"</li>
                        <li>SAHIBUKHARI - Class 2 by Alima Nikhat Haider Aapa | Part 2 of "Life of Imam Bukhari"</li>
                        <li>Recordings - Sahi Bukhari Class 3 by Alima Nikhat Haider Aapa | Topic: "Ilm e Hadees"</li>
                        <li>SAHIBUKHARI - Class 004 SB by Alima Nikhat Haider Aapa | Date: Tuesday, October 8, 2019 | Topic: "KITABUL UL WAHI" (Hadith #1)</li>
                        <li>SAHIBUKHARI - Class 005 SB by Alima Nikhat Haider Aapa | Date: Tuesday, October 22, 2019 | Topic: "KITABUL UL WAHI" (Hadith #2 & 3)</li>
                        <li>SAHIBUKHARI - Class 0006 SB by Alima Nikhat Haider Aapa | Date: Tuesday, October 29, 2019 | Topic: "KITABUL UL WAHI" (Hadith #4 & 5)</li>
                        <li>SAHIBUKHARI - Class 0006 SB by Alima Nikhat Haider Aapa | Date: Tuesday, November 5, 2019 | Topic: "KITABUL UL WAHI" (Hadith #6 & 7)</li>
                        <li>SAHIBUKHARI - Class 0008 SB by Alima Nikhat Haider Aapa | Date: Tuesday, November 12, 2019 | Topic: "KITABUL UL WAHI"</li>
                        <li>SAHIBUKHARI - Class 0009 SB by Alima Nikhat Haider Aapa | Date: Tuesday, November 19, 2019 | Topic: "KITABUL UL IMAAN"</li>
                        <li>Recording - SAHIBUKHARI Class 0010 SB by Alima Nikhat Haider Aapa | Date: Tuesday, December 3, 2019 | Topic: Hadith #9 & 10</li>
                        <li>Recording - SAHIBUKHARI Class 0011 SB by Alima Nikhat Haider Aapa | Date: Tuesday, December 10, 2019 | Topic: Hadith #12, 13, 14, 15</li>
                        <li>Recording - SAHIBUKHARI Class 0012 SB by Alima Nikhat Haider Aapa | Date: Tuesday, January 7, 2020 | Topic: Hadith #14, 15, 16</li>
                        <li>Recording - SAHIBUKHARI Class 0013 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, January 14, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #17, 18</li>
                        <li>Recording - SAHIBUKHARI Class 0013 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, January 21, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #19, 20, 21, 22</li>
                        <li>Recording - SAHIBUKHARI Class 0015 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, January 28, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #23, 24, 25</li>
                        <li>Recording - SAHIBUKHARI Class 0016 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, February 4, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #26, 27, 28</li>
                        <li>Recording - SAHIBUKHARI Class 0017 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, February 11, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #29, 30, 31</li>
                        <li>Recording - SAHIBUKHARI Class 0018 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, February 18, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #32, 33, 34, 35, 36</li>
                        <li>Recording - SAHIBUKHARI Class 0019 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, February 25, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #37, 38, 39, 40</li>
                        <li>Recording - SAHIBUKHARI Class 0020 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, March 3, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #41, 42, 43, 44, 45</li>
                        <li>Recording - SAHIBUKHARI Class 0021 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, March 10, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #46, 47, 48, 49</li>
                        <li>Recording - SAHIBUKHARI Class 0022 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, March 17, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #50, 51, 52</li>
                        <li>Recording - SAHIBUKHARI Class 0023 SB by Aalimah Nikhat Haider Aapa | Date: Tuesday, March 23, 2020 | Topic: "HALAWAT UL INAN" | Hadith: #53, 54, 55, 56, 57, and 58</li>

                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1DqzQMVBqT1tkXBlBOpZF26DhsGD2I9wD&export=download")}>Lecture 2</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1PKJ8_6fNz7ETFYj6r6ayIxU6E64GhxNO&export=download")}>Lecture 3</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1d9aQvsAZlrpxKwboFJNKkiY5II6qVoJB&export=download")}>Lecture 4</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1w8FlxmpbYRNkgcwP9glBfoAhQ0TkNVqZ&export=download")}>Lecture 5</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1GknbjrSnpOMZZOTAbK1aDyjU_wEfeD6w&export=download")}>Lecture 6</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1z2nPIuCviZcCq7br_N0naxZawnRw_dSd&export=download")}>Lecture 7</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1Q9bsAgJ6OKymKGgOVEm5oFJm7mHi8Poh&export=download")}>Lecture 8</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1ie8DSQVkSXULpeWhmV943wD3LDjnmJdV&export=download")}>Lecture 9</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1lyuNCijxJ15IfgJreoEpfxLjdywbIjkZ&export=download")}>Lecture 10</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1V9vvu2BbYSZc3wGOyzlmMklI7vCZQhs_&export=download")}>Lecture 11</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1oT9KACFo78oJ57DzdIJVdscXBgVd93cX&export=download")}>Lecture 12</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1b0mSSWF-4GKki5Y5Z_DlKpnSTmUSJjAJ&export=download")}>Lecture 13</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1ubTTGRv5Kkf1rOANb8r6oi_CIObJDThQ&export=download")}>Lecture 14</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1Wh74Z5VO0s8r73FDOQqeQJ0AnRpPRr33&export=download")}>Lecture 15</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=14scBT3de54MUi2inPDbFLHQ9ART27LS2&export=download")}>Lecture 16</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=10ND_IZx0Jq5L46t-4diXIL4CUgrmUvVg&export=download")}>Lecture 17</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1kK0BVnD9uQyKt38KvnOEgxymr0rzOGZT&export=download")}>Lecture 18</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1Sr8kzktwVkFZMDAbUoiu3uT7BhytpjGR&export=download")}>Lecture 19</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=12FJ8RLW39sN3Gk3eO8-gixsEwYKZk6MW&export=download")}>Lecture 20</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1z7zBjJwARSTLlGvdgu7G9kl4m4zpi6KJ&export=download")}>Lecture 21</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1XEoVPbkis6L46kqeYyY5JnxPXgJUnI1k&export=download")}>Lecture 22</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1739k8OiPQH7qg5w5yjtPVPOO1MbBg7fQ&export=download")}>Lecture 23</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1VIdxwBIITpJ2hUKdbYYpbAheJpJI0X5D&export=download")}>Lecture 24</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1gJPUdzqVAX7QSDNLhIZlDxme9dMtLjcn&export=download")}>Lecture 25</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1aBfJwuyzuzfeMkQAk0kMS1JXFkQZhgxt&export=download")}>Lecture 26</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=11xuV3YSS6xgCPNhX4w7KTNiV26St4_R5&export=download")}>Lecture 27</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1dm1OF0bDxuuyHKgB8JH4UYIbjwWBYpF3&export=download")}>Lecture 28</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1itU0E4e9JLQEpokO-UFFJYBk7Bj9K3pP&export=download")}>Lecture 29</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1MMO-pxKONmwevilt8_vNB6hxo1-I2i40&export=download")}>Lecture 30</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1IfFm5PE9pBbQbAnFLPEXY6hHOFF0sL99&export=download")}>Lecture 31</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1SGrlV4ATmtOgW4MR_PxUzXen4oR7q3w2&export=download")}>Lecture 32</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=12GJFA9_-mJzMdiALKyoh-NmSPxfDsylO&export=download")}>Lecture 33</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1G5yfdN7YNbGnjo-H6STEQwCMdJE8XiO_&export=download")}>Lecture 34</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=16gyo2vNiVh-fk137HVNJL6G5Nck3C-Y_&export=download")}>Lecture 35</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1ROhCmYqhPoVzRkS3qrIgJFL2-_8qMEFx&export=download")}>Lecture 36</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=12DXB_M0t0DbSa_Bol05nyn_YpTYJv4RV&export=download")}>Lecture 37</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1-1Owse5DiueXlE-8jVnHslETb-OVQlSN&export=download")}>Lecture 38</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1YJQTZ2113pPlRShIDzVpDlzNLuyB0j6A&export=download")}>Lecture 39</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1rrwhPcosVW4Xt8ct5mRy8jNeDNgRxy2D&export=download")}>Lecture 40</span>
                    </ul>
                </div>

                <input
                    type="text"
                    placeholder="Search Lectures..."
                    value={search}
                    onChange={handleSearchChange}
                    className="search-lectures"
                />

                <div className='admin-card-container'>
                    {data && filteredData.map(cur => {
                        return (
                            <div key={cur['_id']} className='admin-card'>
                                <div className='admin-card-head'>

                                    <div className='admin-card-title'>{removeSpecificPhrase(cur.name, "By: Aalima Nikhat Haider Aapa")}</div>
                                    <div className='admin-card-date'>{cur.date}</div>
                                </div>
                                <div className='admin-card-bottom'>
                                    {/* <ul>
                    <li>{cur.name}</li>
                </ul> */}
                                    <div className='admin-lecture-container'>
                                        {cur.lectures.map((i, r) => {
                                            return <span
                                                className='admin-btn-link'
                                                onClick={() => handleLinkClick(i, `${removeSpecificPhrase(cur.name, "By: Aalima Nikhat Haider Aapa")}`)}
                                            >
                                                Lecture {r + 1}
                                            </span>;
                                        })}

                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {audioUrl && (
                <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', padding: '10px', background: '#000', borderTop: '1px solid #ccc', paddingBottom: '30px' }}>
                    <h3 style={{ textAlign: 'center', color: 'white' }}>{audioUrlload ? currentLectureName : "Loading Lecture..."}</h3>
                    {audioUrlload && (
                        <audio controls autoPlay style={{ width: '100%', borderRadius: '0' }}>
                            <source src={audioUrl} type="audio/mpeg" />
                            Your browser does not support the audio tag.
                        </audio>
                    )}

                </div>
            )}
        </div>
    );
};


export default Card;

