import React from "react";
import { Carousel } from "antd";
import image from "../../../../assets/quran-background.png";
import Zoom from "react-reveal/Zoom";

const ImageGallery = () => {
  return (
    <div className='image-Container'>
      <div className='image-overlay'>
        <img src={image} alt='Quran Book' className='home-backgorund-img' />
      </div>
      <div className='image-text-container'>
        <Zoom>
          <div className='image-text-top'>The Prophet ﷺ said</div>
          <div className='image-text-middle'>
            The best amoung you (Muslims) are those who learn the Quran and
            teach it
          </div>
          <div className='image-text-end'> -Sahih al-Bukhari</div>{" "}
        </Zoom>
      </div>
    </div>
  );
};

export default ImageGallery;
