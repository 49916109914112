import React from "react";
import { withRouter } from "react-router-dom";
import { ImportOutlined } from "@ant-design/icons";
import { signout } from "../../../../helpers/auth";
import Card from "./Card";
import "../Home.css";

const Home = ({ history }) => {
  // Signout
  const handleSignOut = () => {
    signout(() => {
      history.push("/");
    });
  };

  return (
    <div className='container'>
      <div className='admin-home-container'>
        <div className='admin-nav'>
          <div>
            <div className='admin-title'>Sakeenat Institute Lectures</div>
          </div>
          <button className=' admin-btn' onClick={handleSignOut}>
            <ImportOutlined /> Sign Out
          </button>
        </div>
        <h2 className="className">Sahi Bukhari Class</h2>

        <div className='admin-body-container'>
          <Card />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);

/* <audio controls>
<source src={lecture3} type='audio/mpeg' />
Your browser does not support the audio element.
</audio> */
