import React, { useEffect } from "react";
import Navbar from "../../Layout/Navbar/Navbar";
import ContactBox from "./ContactBox";
import Footer from "../../Layout/Footer/Footer";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Navbar
        color='black'
        resposiveNavColor='black'
        currentNav='green'
        whiteLogo={false}
        underlineBlack={true}
      />

      <div className='container contact-container'>
        <div className='about-banner'>
          <div className='about-text'>Contact us</div>
        </div>
        <ContactBox />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
