import React, { useState, useEffect } from "react";
import axios from 'axios';
import { notification } from 'antd';

const TafseerQuran = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formname, setFormName] = useState(false);
    const [selectedOption, setSelectedOption] = useState('option1');
    const [selectedOptionlectures, setSelectedOptionlectures] = useState('');
    const [inputs, setInputs] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        type: 'Tafseer ul Quran class',
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('/api/getLecture', { type: "Tafseer ul Quran class" });
                setData(response.data);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleClicklecture = () => {
        setFormName(true);
    };

    const handleOptionChange = (event) => {
        setData(data.reverse());
        setSelectedOption(event.target.value);
    };

    const handleOptionChangelectures = (event) => {
        setSelectedOptionlectures(event.target.value);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setButtonLoading(true);

        let { name, date, type } = formData;

        if (!name) {
            setButtonLoading(false);
            return notification.error({
                message: "You need a lecture name!",
            });
        }
        if (!date) {
            setButtonLoading(false);
            return notification.error({
                message: "You need a lecture date!",
            });
        }

        if (inputs.length !== parseInt(selectedOptionlectures)) {
            setButtonLoading(false);
            return notification.error({
                message: "Input all of the lecture google links!",
            });
        }


        let googleLinks = inputs.map(e => {
            const regex = /\/d\/([A-Za-z0-9_-]+)\//;
            const match = regex.exec(e);

            if (!match) {
                return "wrong";
            }

            const id = match[1];
            return `http://docs.google.com/uc?export=open&id=${id}`;
        });

        if (googleLinks.includes('wrong')) {
            setButtonLoading(false);
            return notification.error({
                message: "Invalid google drive link!",
            });
        }
        formData.lectures = googleLinks;
        axios
            .post(`/api/createLecture`, formData)
            .then((response) => {
                setButtonLoading(false);
                setFormData({
                    name: '',
                    date: '',
                    type: 'Tafseer ul Quran class',
                });
                setInputs([]);

                notification.success({
                    message: 'Lecture Created! Refresh To See Lecture!',
                });
                setFormName(false);
            })
            .catch((error) => {
                notification.error({
                    message: error.response.data.error,
                });
                setButtonLoading(false);
            });


    };
    const handleInputChange = (index, value) => {
        const updatedInputs = [...inputs];
        updatedInputs[index] = value;
        setInputs(updatedInputs);
    };

    const handleDeleteClick = (id) => {
        if (window.confirm("Are you sure you want to delete This Lecture!!") == true) {
            axios
                .post(`/api/deleteLecture`, { "_id": id })
                .then((response) => {
                    notification.success({
                        message: 'Lecture has been deleted! Refresh to see it Gone!',
                    });
                })
                .catch((error) => {
                    notification.error({
                        message: error.response.data.error,
                    });
                    setButtonLoading(false);
                });
        }
    };



    return (
        <div>
            <br></br>
            <button className='main-color-scheme-inverted admin-btn' onClick={handleClicklecture} >
                Add A Lecture
            </button>
            <div class="containerOne">
                {formname && (
                    <form className="admin-form" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name">Lecture Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Example: #0024 - Hadith #59 By: Aalima Nikhat Haider Aapa"
                            />
                        </div>
                        <div>
                            <label htmlFor="date">Date:</label>
                            <input
                                type="text"
                                id="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                placeholder="Example: June 2th 2020"
                            />
                        </div>


                        <select className="slectedoption slectedoptions" value={selectedOptionlectures} onChange={handleOptionChangelectures}>
                            <option value="">How Many Lecture do you want to post</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </select>

                        {selectedOptionlectures &&


                            Array.from({ length: parseInt(selectedOptionlectures) }, (_, index) => (
                                <input
                                    key={index}
                                    value={inputs[index] || ''}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    placeholder={`Insert Google Drive Link #${index + 1}`}
                                    className="input-lectures"
                                />
                            ))

                        }
                        <button type="submit"> {buttonLoading ? 'Submiting . . .' : 'Submit'}
                        </button>
                    </form>
                )}


                <select className="slectedoption" value={selectedOption} onChange={handleOptionChange}>
                    <option value="option1">Oldest to Newest Lectures</option>
                    <option value="option2">Newest to Oldest lectures</option>
                </select>
            </div>

            <div className='admin-card-container'>
                {data && data.map(cur => {

                    return (
                        <div key={cur['_id']} className='admin-card'>
                            <button className="delte-btn" onClick={() => handleDeleteClick(cur['_id'])}>Delete</button>
                            <div className='admin-card-head'>
                                <div className='admin-card-title'>{cur.type}</div>
                                <div className='admin-card-date'>{cur.date}</div>
                            </div>
                            <div className='admin-card-bottom'>
                                <ul>
                                    <li>{cur.name}</li>
                                </ul>
                                <div className='admin-lecture-container'>
                                    {cur.lectures.map((i, r) => {
                                        return <a
                                            key={r}
                                            className='admin-btn-link'
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href={i}
                                        >
                                            Lecture {r + 1}
                                        </a>;
                                    })}

                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};



export default TafseerQuran;













