import React, { useState, useEffect } from "react";
import axios from 'axios';
import AWS from 'aws-sdk';
import { notification } from 'antd';

const Card = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedOption, setSelectedOption] = useState('option2');
    const [dropDown, setDropDown] = useState(false);
    const [audioUrl, setAudioUrl] = useState(''); // State to store the audio URL
    const [audioUrlload, setaudioUrlload] = useState(false); // State to store the audio URL
    const [currentLectureName, setCurrentLectureName] = useState(''); // State to store the current lecture name
    const [search, setSearch] = useState(""); // State for search input
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('/api/getLecture', { type: "Tafseer ul Quran class" });
                setData(response.data.reverse());
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const filteredData = data ? data.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
    ) : [];

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    const handleOptionChange = (event) => {
        setData(data.reverse());
        setSelectedOption(event.target.value);
        console.log(event.target.value);
    };

    const DropDownFunc = () => {
        setDropDown(!dropDown);
    };

    const handleLinkClick = (url, lectureName) => {
        setaudioUrlload(false);

        //const idMatch = url.match(/id=([a-zA-Z0-9_-]+)/);

        const regex = /id=([^&]+)/;
        const idMatch = url.match(regex);


        console.log(idMatch);
        if (idMatch) {
            const id = idMatch[1];


            // Configure the AWS SDK to use Wasabi
            const s3 = new AWS.S3({
                endpoint: 's3.wasabisys.com', // Replace with the appropriate endpoint
                accessKeyId: 'I8ITVDL3GT2TQA0ZFSAB', // Replace with your access key
                secretAccessKey: 'DszVpDdzqP1si8PUybJQ3ae3784AJ1vTSN2sqRTq', // Replace with your secret key
                region: 'us-east-1'// Replace with the appropriate region
            });



            const params = {
                Bucket: 'sakeenat', // Replace with your bucket name
                Key: `${id}.mp4`, // Replace with the key of the file you want to retrieve
                Expires: 60 // URL expiry time in seconds
            };



            s3.getSignedUrl('getObject', params, (err, url) => {

                if (err) {
                    notification.error({
                        message: "Unable to open this lecture",
                    });
                    console.log('error', err);
                } else {
                    // window.open(url, '_blank');
                    setAudioUrl(url);
                    setCurrentLectureName(lectureName); // Set the current lecture name
                    setaudioUrlload(true);
                }
            });
        }
        // Open the URL in a new tab
    };
    function removeSpecificPhrase(inputString, phraseToRemove) {
        // Use the replace method with a regex that matches the exact phrase
        const resultString = inputString.replace(new RegExp(phraseToRemove, 'g'), '');

        // Return the modified string
        return resultString;
    }

    return (
        <div>


            <select className="slectedoption slectedoptions" value={selectedOption} onChange={handleOptionChange}>
                <option value="option1">Oldest to Newest Lectures</option>
                <option value="option2">Newest to Oldest lectures</option>
            </select>


            <button className="dropDowmLectures" onClick={DropDownFunc} >Lectures 2019 #1 - #3</button>
            <div style={{ marginBottom: '10rem' }}>
                <div style={{ display: dropDown ? "block" : "none" }}>


                    <ul className="recording-list">
                        <li>0009 TQ- "SURAH AL BAQARAH " Ayat # 21 to 29 Thursday DEC 12th 2019 by Aalimah Nikhat Haider Aapa</li>

                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=1TD8T06hs84UdquJj2hJliSnuEdMS9zKl&export=download")}>Lecture 1</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=18o8hlp9dgeQ3T5qeo85k_7z26--up5af&export=download")}>Lecture 2</span>
                        <span class="admin-btn-link" onClick={() => handleLinkClick("https://drive.usercontent.google.com/u/2/uc?id=15SIrxrWnLwpCMHtFHbGuufKbm8PZYzu3&export=download")}>Lecture 3</span>
                    </ul>
                </div>

                <input
                    type="text"
                    placeholder="Search Lectures..."
                    value={search}
                    onChange={handleSearchChange}
                    className="search-lectures"
                />


                <div className='admin-card-container'>
                    {data && filteredData.map(cur => {
                        return (
                            <div key={cur['_id']} className='admin-card'>
                                <div className='admin-card-head'>
                                    <div className='admin-card-title'>{removeSpecificPhrase(cur.name, "By: Aalima Nikhat Haider Aapa")}</div>
                                    <div className='admin-card-date'>{cur.date}</div>
                                </div>
                                <div className='admin-card-bottom'>
                                    {/* <ul>
                    <li>{cur.name}</li>
                </ul> */}
                                    <div className='admin-lecture-container'>
                                        {cur.lectures.map((i, r) => {
                                            return <span
                                                className='admin-btn-link'
                                                onClick={() => handleLinkClick(i, `${removeSpecificPhrase(cur.name, "By: Aalima Nikhat Haider Aapa")}`)}
                                            >
                                                Lecture {r + 1}
                                            </span>;
                                        })}

                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {audioUrl && (
                <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', padding: '10px', background: '#000', borderTop: '1px solid #ccc', paddingBottom: '30px' }}>
                    <h3 style={{ textAlign: 'center', color: 'white' }}>{audioUrlload ? currentLectureName : "Loading Lecture..."}</h3>
                    {audioUrlload && (
                        <audio controls autoPlay style={{ width: '100%', borderRadius: '0' }}>
                            <source src={audioUrl} type="audio/mpeg" />
                            Your browser does not support the audio tag.
                        </audio>
                    )}

                </div>
            )}
        </div>
    );
};


export default Card;

