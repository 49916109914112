import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-text'>
        © 2022 Sakeenat Islamic Institue. All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
