import React from 'react'

const MondayDarsLectures = () => {
	return (
		<div>
			      {/* Class */}
						<div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Muharram, how to start our Islamic new year</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1IoT9a1d8cRXWDEkbBrxMX3Pw5Uagm_47'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1teE1gDYobfHt6ToSeYEzuhLT_2dp3qTt'
            >
              Lecture Two
            </a>
          </div>
        </div>
      </div>

      {/* Class */}
      <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Time Management</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1IoT9a1d8cRXWDEkbBrxMX3Pw5Uagm_47'
            >
              Lecture One
            </a>
          </div>
        </div>
      </div>

      {/* Class */}
      <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Husn e Islam</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1E--3hmf-0r0BPq-76irn6ZnA5qVKRyXt'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1PQWpe_P2YIDmfSyzfju7kR4_6dKRscTe'
            >
              Lecture Two
            </a>
          </div>
        </div>
      </div>

      {/* Class */}
      <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Namaaz e Tahajjud</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1uO6OspKDQls6hkRWQsJYkl7WLdHa4qX3'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1_3tu5Q_xl7bH-tnNYU0Jr1GbivMkOXVf'
            >
              Lecture Two
            </a>
          </div>
        </div>
      </div>

      {/* Class */}
      <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Dua’a kay aadaab</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1rnpk8pMgMYazzco2b4gJr4mIc4t0XVQU'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=179AczgdFtkKwdsOBF28dB5cyNJc3t1tK'
            >
              Lecture Two
            </a>
          </div>
        </div>
      </div>

            {/* Class */}
            <div className='admin-card'>
        <div className='admin-card-head'>
          <div className='admin-card-title'>Monday dars </div>
          <div className='admin-card-date'></div>
        </div>
        <div className='admin-card-bottom'>
          <ul>
            <li>Topic: Fitnon ki pehchaan</li>
            <li>By Aalima Nikhat Haider Aapa</li>
          </ul>
          <div className='admin-lecture-container'>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1p0CA-W6Xql8iZzb3jNeHHGCxxedc5Y95'
            >
              Lecture One
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1XksvJ5cQXqMpppYs38ikE-NH5fvwXPtG'
            >
              Lecture Two
            </a>
            <a
              className='admin-btn-link'
              rel='noopener noreferrer'
              target='_blank'
              href='http://docs.google.com/uc?export=open&id=1cDOdr6jDl0V80CVxDzv2Hg6M92tc9ds3'
            >
              Lecture Three
            </a>
          </div>
        </div>
      </div>
		</div>
	)
}

export default MondayDarsLectures
