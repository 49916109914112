import React, { useState } from "react";
import { Drawer, Menu, Dropdown } from "antd";
import { MenuOutlined, LoginOutlined } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import SakeenatLogo from "../../../assets/imageedit_7_6831998141.png";

const DrawerNavbar = ({ match }) => {
  const [visable, setVisable] = useState(false);

  const navigation = (path) => {
    if (path === match.path) {
      return {
        style: { color: "green" },
        class: "nav-link middle-black middle-visable-black",
      };
    } else {
      return {
        style: { color: "black" },
        class: "nav-link middle-black ",
      };
    }
  };

  const showDrawer = () => {
    setVisable(true);
  };

  const onClose = () => {
    setVisable(false);
  };

  return (
    <div className='drawer-Nav'>
      <div className='drawer-burger'>
        <div>
          <img className='nav-logo' src={SakeenatLogo} alt='Logo' />
          <div>
            <MenuOutlined className='drawer-bars' onClick={showDrawer} />
          </div>
        </div>
      </div>

      <Drawer
        title={<h1 style={{ textAlign: "center" }}>Sakeenat</h1>}
        placement='right'
        closable={false}
        onClose={onClose}
        visible={visable}
        width='220'
      >
        <h2 style={{ marginBottom: "2rem" }}>
          <Dropdown
            trigger={["click"]}
            className='hoverable'
            overlay={
              <Menu>
                <Menu.Item>
                  <Link to='/'>Home</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to={{
                      pathname: "/",
                      state: {
                        scrollTo: "about",
                      },
                    }}
                  >
                    About
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to={{
                      pathname: "/",
                      state: {
                        scrollTo: "classes",
                      },
                    }}
                  >
                    Classes
                  </Link>
                </Menu.Item>
                {/* <Menu.Item>
                  <Link
                    to={{
                      pathname: '/',
                      state: {
                        scrollTo: 'events',
                      },
                    }}
                  >
                    Events
                  </Link>
                </Menu.Item> */}
                <Menu.Item>
                  <Link
                    to={{
                      pathname: "/",
                      state: {
                        scrollTo: "announcements",
                      },
                    }}
                  >
                    Announcements
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <span style={navigation("/").style} className={navigation("/").class}>
              Home
            </span>
          </Dropdown>
        </h2>
        <h2 style={{ marginBottom: "2rem" }}>
          <Link
            style={navigation("/about").style}
            to='/about'
            className={navigation("/about").class}
          >
            About
          </Link>
        </h2>
        <h2 style={{ marginBottom: "2rem" }}>
          <Dropdown
            trigger={["click"]}
            className='hoverable'
            overlay={
              <Menu>
                <Menu.Item>
                  <Link to='/islamic-knowledge/allah-names'>Allah Names</Link>
                </Menu.Item>
                <Menu.Item>
                  <a rel='noopener noreferrer' target='_blank' href='https://quran.com/'>
                    Full Quran
                  </a>
                </Menu.Item>
              </Menu>
            }
          >
            <span
              style={navigation("/islamic-knowledge").style}
              className={navigation("/islamic-knowledge").class}
            >
              Islamic Knowledge
            </span>
          </Dropdown>
        </h2>
        <h2 style={{ marginBottom: "2rem" }}>
          <Dropdown
            trigger={["click"]}
            className='hoverable'
            overlay={
              <Menu>
                <Menu.Item>
                  <Link to='/lectures/youtube'>Youtube Lectures</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to='/lectures'>Audio Lectures</Link>
                </Menu.Item>
              </Menu>
            }
          >
            <span
              style={navigation("/lectures").style}
              className={navigation("/lectures").class}
            >
              Lectures
            </span>
          </Dropdown>
        </h2>
        <h2 style={{ marginBottom: "2rem" }}>
          <Link
            style={navigation("/contacts").style}
            to='/contacts'
            className={navigation("/contacts").class}
          >
            Contact
          </Link>
        </h2>
        <h2 style={{ marginBottom: "2rem" }}>
          <Link to='/sign-in'>
            <button className='sign-in-button main-color-scheme'>
              <LoginOutlined /> Sign In
            </button>
          </Link>
        </h2>
      </Drawer>
    </div>
  );
};

export default withRouter(DrawerNavbar);
