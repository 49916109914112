import React, { useEffect, useState } from 'react';

const FacebookFeed = () => {
  return (
    <div className='facebook-container'>
      <h2 className='facebook-title'>Facebook Feed</h2>
      <iframe
        src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSakeenat-Ibadurahman-Institute-102942941057060%2F%3Fref%3Dpage_internal&tabs=timeline&width=320&height=750&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=288225272187957'
        scrolling='no'
        frameBorder='0'
        allowtransparency='true'
        allow='encrypted-media'
        className='facebook-container'
      ></iframe>
    </div>
  );
};

export default FacebookFeed;
