import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";

const Announcements = () => {
  return (
    <div className='announcements'>
      {/* Announcements Card */}
      <div className='announcemebnt-sub-container'>
        {/* <div className='announcement-dec'>
          We also have some registered classes with Aalima Nikhat Haider Aapa resuming
          from Tuesday 2nd June 2020.
        </div> */}

        {/* Contact for classes */}
        <div className='announcements-contact-container'>
          <div style={{ fontSize: "1rem", fontWeight: "900" }}>
            If any of u want to register in these classes please reach out the following{" "}
          </div>
          <div className='announcements-contact-info'>
            <span>
              <u>Afsheen Arif:</u>
            </span>{" "}
            (858)-356-7294
          </div>
          <div className='announcements-contact-info'>
            <span>
              <u>Farida Sharif:</u>
            </span>{" "}
            (773)-809-6934
          </div>
        </div>

        {/* Class 1 */}
        <div className='class-container-style'>
          <h1 className='classes-name' style={{ fontSize: "1.1rem" }}>
            <span className='right-carrot'>
              <CaretRightOutlined />
            </span>
            Sahi bukhari <span className='date-occurrence'>(Tuesday)</span>
          </h1>
          <div className='class-sub-text-container'>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Timings:</span>
                <span className='class-decription-text'> 11:am to 12:30pm </span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Attendance:</span>
                <span className='class-decription-text'>Join Through Phone only</span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Location:</span>
                <span className='class-decription-location'>None</span>
              </div>
            </div>
          </div>
        </div>

        {/* Class 2 */}
        <div className='class-container-style'>
          <h1 className='classes-name' style={{ fontSize: "1.1rem" }}>
            <span className='right-carrot'>
              <CaretRightOutlined />
            </span>
            Word to word Quran meaning and tafseer{" "}
            <span className='date-occurrence'>(Thursday)</span>
          </h1>
          <div className='class-sub-text-container'>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Timings:</span>
                <span className='class-decription-text'> 11:00am to 12:30pm </span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Attendance:</span>
                <span className='class-decription-text'>Join Through Phone only</span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Location:</span>
                <span className='class-decription-location'>None</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcements;
