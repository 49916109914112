import React from "react";
import { PhoneFilled, CaretRightOutlined } from "@ant-design/icons";

const CurrentClasses = () => {
  return (
    <div className='class-container'>
      <div className='class-top-container'>
        <div style={{ textAlign: "left" }} className='classes-alert'>
          Due To Covid-19 all classes will be conducted through the phone
        </div>

        {/* <div style={{ textAlign: "left" }} className='announcement-dec'>
          In sha Allah all classes with Aalima Nikhat Haider Aapa will resume from Monday,
          1st June 2020
        </div> */}
      </div>

      {/* Contact */}
      <div className='announcements-contact-container extra'>
        <div className='announcements-contact-title'>
          Classes will be on phone only <PhoneFilled />
        </div>
        <div className='announcements-contact-info'>
          <span>
            <u>Free backup phone:</u>
          </span>{" "}
          1-(425)-535-9151
        </div>
        <div className='announcements-contact-info'>
          <span>
            <u>Conference Dial in:</u>
          </span>{" "}
          978 990 5000#
        </div>
        <div className='announcements-contact-info'>
          <span>
            <u>Access code:</u>
          </span>{" "}
          862551#
        </div>
      </div>

      <div className='class-main-container'>
        <div className='class-container-style'>
          <h1 className='classes-name'>
            <span className='right-carrot'>
              <CaretRightOutlined />
            </span>
            Dars e Quran o Hadith <span className='date-occurrence'>(Every Monday)</span>
          </h1>
          <div className='class-sub-text-container'>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Timings:</span>
                <span className='class-decription-text'>11:00 am - 1:30 pm</span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Attendance:</span>
                <span className='class-decription-text'>Join Through Phone only</span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Location:</span>
                <span className='class-decription-location'>None</span>
              </div>
            </div>
          </div>
          <hr className='event-line' />
        </div>

        {/* New Class */}
        <div className='class-container-style'>
          <h1 className='classes-name'>
            <span className='right-carrot'>
              <CaretRightOutlined />
            </span>
            Hifz e Quran <span className='date-occurrence'>(Every Tuesday)</span>
          </h1>
          <div className='class-sub-text-container'>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Timings:</span>
                <span className='class-decription-text'>3:30 pm - 4:30 pm</span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Attendance:</span>
                <span className='class-decription-text'>Join Through Phone only</span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Location:</span>
                <span className='class-decription-location'>None</span>
              </div>
            </div>
          </div>
          <hr className='event-line' />
        </div>

        {/* New Class */}
        <div className='class-container-style'>
          <h1 className='classes-name'>
            <span className='right-carrot'>
              <CaretRightOutlined />
            </span>
            Q&A and any Medical topic discussion{" "}
            <span className='date-occurrence'>(Every Thursday)</span>
          </h1>
          <div className='class-sub-text-container'>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Timings:</span>
                <span className='class-decription-text'>3:30 pm - 4:30 pm</span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Class Attendance:</span>
                <span className='class-decription-text'>Join Through Phone only</span>
              </div>
            </div>
            <div className='class-decription-container'>
              <div>
                <span className='class-description-title'>Location:</span>
                <span className='class-decription-location'>None</span>
              </div>
            </div>
          </div>

          <hr className='event-line' />
        </div>
      </div>
    </div>
  );
};

export default CurrentClasses;
