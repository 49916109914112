import React, { useEffect, useState } from 'react';
import { Form, Input, notification } from 'antd';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import AuthBanner from './AuthBanner';

const ResetPassword = ({ history, match }) => {
  const [stateValues, setValues] = useState({
    name: '',
    resetPasswordLink: '',
    buttonLoding: false,
  });

  // reset form
  const [form] = Form.useForm();

  const { buttonLoding } = stateValues;

  useEffect(() => {
    // Get Token From Url
    const token = match.params.token;

    // Checking if invalid token
    if (!jwt.decode(token)) {
      return history.push('/sign-in');
    }

    // Decode Token (Pulling name out)
    const { name } = jwt.decode(token);

    // Setting State Values
    if (token) setValues({ ...stateValues, name, resetPasswordLink: token });
  }, []);

  const onFinish = (values) => {
    setValues({ ...stateValues, buttonLoding: true });
    axios
      .put(`/api/reset-password`, {
        resetPasswordLink: stateValues.resetPasswordLink,
        newPassword: values.confirm,
      })
      .then((result) => {
        setValues({
          ...stateValues,
          name: '',
          resetPasswordLink: '',
          buttonLoding: false,
        });
        form.resetFields();
        notification.success({
          message: result.data.message,
        });
      })
      .catch((error) => {
        setValues({ ...stateValues, buttonLoding: false });

        notification.error({
          message: error.response.data.error,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const resetPasswordForm = (
    <Form
      name='basic'
      layout='vertical'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        name='password'
        label='Password'
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name='confirm'
        label='Confirm Password'
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <button disabled={buttonLoding} className='contact-button main-color-scheme'>
          {buttonLoding ? 'Submiting . . .' : 'Submit'}
        </button>
      </Form.Item>
    </Form>
  );

  return (
    <div>
      <AuthBanner />
      <div className='auth-input-container'>
        <h1
          className='input-title'
          style={{ textTransform: 'capitalize', marginBottom: '1rem' }}
        >
          {stateValues.name} Reset Password
        </h1>
        {resetPasswordForm}
      </div>
    </div>
  );
};

export default ResetPassword;
