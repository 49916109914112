import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const AboutHome = () => {
  return (
    <div className='about-home-container'>
      <div className='main-about-home-title'>About Sakeenat</div>
      <p className='main-about-home-paragraph'>
        Sakeenat Ibad-ur-Rehman has been serving thirteen years its local community
        through in-person classes, and is outreaching muslims in United States and globaly
        through its conference phone sessions
      </p>
      <Link to='/about'>
        <button className='main-color-scheme read-more-btn'>
          Read More <ArrowRightOutlined />
        </button>
      </Link>
      <div className='main-about-home-title'>Teacher's Profile</div>
      <p className='main-about-home-paragraph'>
        Aalimah Nikhat Haider Completed course from al-ehsaan.net. Expertise Quran, Hadith,
        Fiqh, Arabic Grammer, Virasat, Islamic. Hundreds of Muslims are currently
        benefiting from its programs.
      </p>
      <Link to='/about'>
        <button className='main-color-scheme read-more-btn'>
          Read More <ArrowRightOutlined />
        </button>
      </Link>
    </div>
  );
};

export default AboutHome;
